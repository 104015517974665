import * as React from 'react';
import { TFunction } from 'i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/styles';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import SocialSharePanel, { SocialShareTypeEnum } from '.';
import DialogTitle from '../Dialog/DialogTitle';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import { UrlPath } from '../../types/Enums';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 5,
  },
  container: {
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      width: '400px',
    },
    gap: theme.spacing(5),
  },
  button: {
    backgroundColor: theme.palette.dividers.secondary,
    height: '34px',
    flexDirection: 'row',
    width: '160px',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 700,
    lineHeight: theme.typography.body1.lineHeight,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  copyIcon: {
    display: 'flex',
    background: theme.palette.dividers.primary,
    borderWidth: 1,
    borderColor: theme.palette.dividers.primary,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    width: 70,
    height: 70,
    marginBottom: 5,
    cursor: 'pointer',
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(5),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
  },
}));

interface SocialShareButtonProps {
  title?: string;
  productUrl?: string;
}

interface ShareDialogProps {
  title?: string;
  productUrl?: string;
  t: TFunction;
}

export interface ShareDialogHandle {
  open: () => void;
  close: () => void;
}

export const ShareDialog = React.forwardRef(({ productUrl, title, t }: ShareDialogProps, ref) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const _productUrl = React.useMemo(() => {
    return `${window.location.origin}${UrlPath.PRODUCT_PAGE}/${productUrl}`;
  }, [productUrl]);

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="social-share-dialog-title" onClose={handleClose}>
        <span>{t('product:socialShare.modalTitle')}</span>
      </DialogTitle>
      <DialogContentBorderTopOnly dividers>
        <div className={classes.container}>
          <SocialSharePanel
            options={{
              size: 70,
              showLabel: true,
            }}
            url={_productUrl}
            title={title || ''}
            socials={[
              {
                type: SocialShareTypeEnum.EAMIL,
                label: t(`product:socialShare.types.email`),
                className: '',
              },
              {
                type: SocialShareTypeEnum.WHATSAPP,
                label: t(`product:socialShare.types.whatsapp`),
                className: '',
              },
              {
                type: SocialShareTypeEnum.Twitter,
                label: t(`product:socialShare.types.twitter`),
                className: '',
              },
              {
                type: SocialShareTypeEnum.CUSTOM,
                label: t(`product:socialShare.types.copyLink`),
                className: 'cursor-pointer',
                icon: (
                  <div className={classes.copyIcon}>
                    <ContentCopyOutlinedIcon />
                  </div>
                ),
                onClick: () => {
                  navigator.clipboard.writeText(_productUrl);
                  enqueueSnackbar(t('product:socialShare.copied'), { variant: 'info' });
                },
              },
            ]}
          />
        </div>
      </DialogContentBorderTopOnly>
    </BootstrapDialog>
  );
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function SocialShareButton({ title, productUrl = '' }: SocialShareButtonProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const shareDialogRef = React.useRef<ShareDialogHandle>();

  return (
    <div className={classes.wrapper}>
      <Button
        variant="contained"
        className={classes.button}
        size="medium"
        onClick={() => shareDialogRef.current?.open()}
      >
        <span>{t('product:socialShare.buttonText')}</span> <ShareOutlinedIcon />
      </Button>
      <ShareDialog title={title} productUrl={productUrl} t={t} ref={shareDialogRef} />
    </div>
  );
}
